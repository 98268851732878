/* eslint-disable prefer-rest-params */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useCallback } from 'react';
import router from 'next/router';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { PulseLoader } from 'react-spinners';
// import { useFlags } from 'launchdarkly-react-client-sdk';
// Hooks
import { useAppDispatch, useAppSelector } from '@hooks/redux';
// Redux
import { setVAT } from 'src/redux/VATSlice';
// Utils
import { checkoutLink } from '@utils/checkoutLink';
import { setPricesBasket } from '@utils/guestBasketHelpers';
import getCookies from '@utils/getCookies';
import dateToMMDDYYYY from '@utils/dateToMMDDYYYY';
// Types
import Company from 'types/company';
import { Basket } from 'types/product';
// Components
import { GTagCategory } from 'types/googleAnalytics';
import HeaderBasketTable from '@components/NavHeader/components/HeaderBasket/components/HeaderBasketTable';
import HeaderBasketSummaryTable from '@components/NavHeader/components/HeaderBasket/components/HeaderBasketSummaryTable';
import {
  removeBasketItem,
  updateBasketItem,
  setGuestBasket,
  getBasket,
  addBasketItem,
} from 'src/redux/basketSlice';
// import NextDayDeliveryTimer from '@components/Basket/NextDayDeliveryTimer';
import { productDetailServiceApiUrl } from '@utils/ApiServices';

import axios from 'axios';
import dyAddToCart from '@utils/dyAddToCart';

declare const dataLayer: any;
declare const fbq: any;

const HeaderBasketHoliday = dynamic(
  () => import('@components/NavHeader/components/HeaderBasket/components/HeaderBasketHoliday')
);

// Duplicate FoundIt Exp
import { setIsFoundItExp } from 'src/redux/foundItSlice';
import { fbEvent } from '@utils/facebook';
import { FbEventName } from 'types/facebook';
import uuidv4 from '@utils/uuidv4';

interface basketProps {
  companyID: Company;
  baseUrl: string | undefined;
  apiKey: string | undefined;
  handleClickReset: () => void;
}

declare const gtag: any;

function HeaderBasket({ companyID, baseUrl, apiKey, handleClickReset }: basketProps): JSX.Element {
  const [guestUserBasket, setGuestUserBasket] = useState<Basket[]>(null);
  const month = new Date().getMonth() + 1;
  const day = new Date().getDate();
  const bankHoliday = month === 8 && day >= 22 && day <= 26;

  // const flags: {
  //   thresholdVariation: {
  //     price: string;
  //     threshold: string;
  //   };
  // } = useFlags();
  // const { thresholdVariation } = flags;
  const dispatch = useAppDispatch();
  const { showVat } = useAppSelector((state) => state.vat);
  const { basketTotal, basketItems, basketId, isLoading, isLoadingUpdate } = useAppSelector(
    (state) => state.basket
  );
  const { cardCode, details } = useAppSelector((state) => state.user);
  const [dySkuCode, setDySkuCode] = useState<string>('');

  const handleClickBasketUpdate = (
    basketID: number,
    skuCode: string,
    quantity: number,
    basketItemId: number
  ): void => {
    const token = getCookies('authToken');
    if (getCookies('authToken') !== '' && getCookies('authEmail') !== '') {
      dispatch(
        updateBasketItem({
          companyID,
          baseUrl,
          apiKey,
          basketId: basketID,
          accountCode: cardCode,
          emailAddress: getCookies('authEmail'),
          skuCode,
          quantity,
          basketItemId,
          token,
        })
      );
    } else {
      const existingBasket = localStorage.getItem('guestBasket');
      if (existingBasket) {
        const parsedExistingBasket: Basket[] = setPricesBasket(JSON.parse(existingBasket));
        if (
          parsedExistingBasket.filter((basketItem: Basket) => basketItem.skuCode === skuCode)
            .length > 0
        ) {
          parsedExistingBasket[
            parsedExistingBasket.findIndex((basketItem: Basket) => basketItem.skuCode === skuCode)
          ].quantity = quantity;
          localStorage.setItem(
            'guestBasket',
            JSON.stringify(setPricesBasket(parsedExistingBasket))
          );
          const parsedGuestBasketTotal = parsedExistingBasket.reduce(
            function (prev: { sum: number }, curr: Basket) {
              if (curr.prices.salePrice) {
                prev.sum += curr.prices.salePrice * curr.quantity;
              } else {
                prev.sum += curr.prices.price * curr.quantity;
              }
              return prev;
            },
            { sum: 0 }
          ).sum;
          setGuestUserBasket(setPricesBasket(parsedExistingBasket));
          dispatch(
            setGuestBasket({
              guestBasketTotal: parsedGuestBasketTotal,
              guestBasketItems: setPricesBasket(parsedExistingBasket),
            })
          );
        }
      }
    }
  };

  const handleClickBasketRemove = (
    basketID: number,
    skuCode: string,
    basketItemId: number,
    index: number
  ): void => {
    const token = getCookies('authToken');
    if (getCookies('authToken') !== '' && getCookies('authEmail') !== '') {
      dispatch(
        removeBasketItem({
          companyID,
          baseUrl,
          apiKey,
          basketId: basketID,
          accountCode: cardCode,
          emailAddress: getCookies('authEmail'),
          skuCode,
          basketItemId,
          token,
        })
      );
    } else {
      const existingBasket = localStorage.getItem('guestBasket');
      if (existingBasket) {
        const parsedExistingBasket: Basket[] = JSON.parse(existingBasket);
        const existingBasketDelete = parsedExistingBasket.filter(
          (_basketItem: Basket, i) => i !== index
        );
        localStorage.setItem('guestBasket', JSON.stringify(existingBasketDelete));
        const parsedGuestBasketTotal = existingBasketDelete.reduce(
          function (prev: { sum: number }, curr: Basket) {
            if (curr.prices.salePrice) {
              prev.sum += curr.prices.salePrice * curr.quantity;
            } else {
              prev.sum += curr.prices.price * curr.quantity;
            }
            return prev;
          },
          { sum: 0 }
        ).sum;
        setGuestUserBasket(existingBasketDelete);
        dispatch(
          setGuestBasket({
            guestBasketTotal: parsedGuestBasketTotal,
            guestBasketItems: existingBasketDelete,
          })
        );
      }
    }
  };

  const localStorageSetHandler = useCallback(
    (e): void => {
      const localStorageAuthToken = getCookies('authToken');
      if (e.key === 'guestBasket') {
        if (e.value && e.value.length > 0) setGuestUserBasket(JSON.parse(e.value));
        else setGuestUserBasket([]);
      }

      if (e.key === 'increasinglyAddToBasket') {
        localStorage.removeItem('increasinglyAddToBasket');
        dispatch(getBasket({ companyID, baseUrl, apiKey, token: localStorageAuthToken }));
      }

      // set _dyid_server cookie on first page load
      if (e.key === '_dyid') {
        if (e.value && e.value.length > 0) {
          const dyid = e.value;
          const dyidServer = getCookies('_dyid_server');

          if (!dyidServer) {
            document.cookie = `_dyid_server=${dyid};max-age=31556951;secure`;
          }
        }
      }

      if (e.key === 'dy_atb') {
        if (e.value && e.value.length > 0) {
          setDySkuCode(e.value);
        }
      }
    },
    [apiKey, baseUrl, companyID, dispatch]
  );

  useEffect(() => {
    const addDySku = async (): Promise<void> => {
      const connectProductDetailService = productDetailServiceApiUrl(dySkuCode);

      await axios
        .get(connectProductDetailService.url, connectProductDetailService.apiConfiguration)
        .then(async (response) => {
          if (response.data.skuCompanyId && response.data.skuCompanyId === companyID) {
            const dyBasketItem = response.data;
            const quantity = 1;
            // Dynamic Yield Add to Cart Event
            dyAddToCart(dyBasketItem.vatPriceBreaks, basketItems, {
              productId: String(dyBasketItem.id),
              quantity: 1,
              itemPrice: dyBasketItem.vatPrice,
            });

            const token = getCookies('authToken');
            const authEmail = getCookies('authEmail');

            // GA Add to Cart Event
            dataLayer.push({
              event: 'add_to_cart',
              currency: 'GBP',
              value: dyBasketItem.price,
              items: [
                {
                  item_id: dyBasketItem.skuCode,
                  item_name: dyBasketItem.name,
                  affiliation: 'Online',
                  discount: 0.0,
                  currency: 'GBP',
                  index: 0,
                  item_brand: dyBasketItem.brand,
                  item_category: dyBasketItem.catLvl3Name,
                  price: dyBasketItem.price,
                  quantity: 1,
                },
              ],
            });

            const fbEventId = uuidv4();

            // FB Conversions API Add to Cart Event
            fbEvent({
              eventId: fbEventId,
              eventName: FbEventName.ADD_TO_CART,
              eventSourceUrl: router.asPath,
              email: authEmail,
              firstName: details?.firstName,
              lastName: details?.lastName,
              contentIds: [dyBasketItem.skuCode],
              contentType: 'product',
              contents: [{ id: dyBasketItem.skuCode, quantity: 1, itemPrice: dyBasketItem.price }],
              value: quantity * dyBasketItem.price,
              contentName: dyBasketItem.name,
            });

            // Facebook Pixel Add to Cart
            fbq(
              'track',
              'AddToCart',
              {
                content_ids: [dyBasketItem.skuCode],
                content_type: 'product',
              },
              {
                eventID: fbEventId,
              }
            );

            if (getCookies('authToken') !== '' && getCookies('authEmail') !== '') {
              dispatch(
                addBasketItem({
                  companyID,
                  baseUrl,
                  apiKey,
                  basketId: basketId,
                  accountCode: cardCode,
                  emailAddress: getCookies('authEmail'),
                  skuCode: Number(dyBasketItem.skuCode),
                  quantity: quantity || 1,
                  length: null,
                  width: null,
                  screwholes: null,
                  calculatedPrice: null,
                  token,
                })
              );
            } else {
              const existingBasket = localStorage.getItem('guestBasket');
              if (existingBasket !== null && quantity) {
                const parsedExistingBasket: Basket[] = setPricesBasket(JSON.parse(existingBasket));
                if (
                  parsedExistingBasket.filter(
                    (existingBasketItem: Basket) => existingBasketItem.id === dyBasketItem.id
                  ).length > 0
                ) {
                  parsedExistingBasket[
                    parsedExistingBasket.findIndex(
                      (parsedBasketItem: Basket) => parsedBasketItem.id === Number(dyBasketItem.id)
                    )
                  ].quantity =
                    parsedExistingBasket[
                      parsedExistingBasket.findIndex(
                        (parsedBasketItem: Basket) =>
                          parsedBasketItem.id === Number(dyBasketItem.id)
                      )
                    ].quantity + quantity;
                  localStorage.setItem(
                    'guestBasket',
                    JSON.stringify(setPricesBasket(parsedExistingBasket))
                  );
                  const parsedGuestBasketTotal = parsedExistingBasket.reduce(
                    function (prev: { sum: number }, curr: Basket) {
                      prev.sum += curr.quantity * curr.prices.price;
                      return prev;
                    },
                    { sum: 0 }
                  ).sum;
                  dispatch(
                    setGuestBasket({
                      guestBasketTotal: parsedGuestBasketTotal,
                      guestBasketItems: setPricesBasket(parsedExistingBasket),
                    })
                  );
                } else {
                  localStorage.setItem(
                    'guestBasket',
                    JSON.stringify(
                      setPricesBasket([
                        ...parsedExistingBasket,
                        {
                          addedByVoucher: false,
                          expectedDeliveryDate: dyBasketItem.expectedDeliveryDate,
                          id: +dyBasketItem.skuCode,
                          isBackOrder: dyBasketItem.isBackOrder,
                          isDirect: dyBasketItem.isDirectDelivery,
                          isHazardous: dyBasketItem.isHazardous,
                          isLongLength: dyBasketItem.isLongLength,
                          m2mHeight: dyBasketItem?.length || 0,
                          m2mScrewholes: dyBasketItem?.screwholes || null,
                          m2mWidth: dyBasketItem?.width || 0,
                          name: dyBasketItem.name,
                          prices: {
                            decimalSalePercentage: dyBasketItem.skuOffers.discount,
                            price: dyBasketItem.skuOffers.was || dyBasketItem.price,
                            priceBreaks: dyBasketItem.priceBreaks,
                            salePercentage: dyBasketItem.skuOffers.discount,
                            salePrice: dyBasketItem.skuOffers.now,
                            vatPrice: dyBasketItem.vatPrice,
                            vatPriceBreaks: dyBasketItem.vatPriceBreaks,
                          },
                          quantity: quantity || 1,
                          skuCode: dyBasketItem.skuCode,
                          stockLevel: dyBasketItem.stockLevel,
                          url: dyBasketItem.skuUrl,
                          leadTime: dyBasketItem.leadTimeInfo,
                          brand: dyBasketItem.brand,
                          category: dyBasketItem.categoryLvl3Name,
                        },
                      ])
                    )
                  );
                  let price = dyBasketItem.price;
                  // apply bulk discount to price
                  dyBasketItem.priceBreaks.forEach((priceBreak) => {
                    if (quantity >= priceBreak.quantity) {
                      price = priceBreak.price;
                    }
                  });
                  dispatch(
                    setGuestBasket([
                      ...parsedExistingBasket,
                      {
                        guestBasketTotal: quantity * price,
                        guestdyBasketItems: setPricesBasket([
                          {
                            addedByVoucher: false,
                            expectedDeliveryDate: dyBasketItem.expectedDeliveryDate,
                            id: +dyBasketItem.skuCode,
                            isBackOrder: dyBasketItem.isBackOrder,
                            isDirect: dyBasketItem.isDirectDelivery,
                            isHazardous: dyBasketItem.isHazardous,
                            isLongLength: dyBasketItem.isLongLength,
                            m2mHeight: dyBasketItem?.length || 0,
                            m2mScrewholes: dyBasketItem?.screwholes || null,
                            m2mWidth: dyBasketItem?.width || 0,
                            name: dyBasketItem.name,
                            prices: {
                              decimalSalePercentage: dyBasketItem.skuOffers.discount,
                              price: dyBasketItem.skuOffers.was || dyBasketItem.price,
                              priceBreaks: dyBasketItem.priceBreaks,
                              salePercentage: dyBasketItem.skuOffers.discount,
                              salePrice: dyBasketItem.skuOffers.now,
                              vatPrice: dyBasketItem.vatPrice,
                              vatPriceBreaks: dyBasketItem.vatPriceBreaks,
                            },
                            quantity: quantity || 1,
                            skuCode: dyBasketItem.skuCode,
                            stockLevel: dyBasketItem.stockLevel,
                            url: dyBasketItem.skuUrl,
                            leadTime: dyBasketItem.leadTimeInfo,
                            brand: dyBasketItem.brand,
                            category: dyBasketItem.categoryLvl3Name,
                          },
                        ]),
                      },
                    ])
                  );
                }
              } else {
                localStorage.setItem(
                  'guestBasket',
                  JSON.stringify(
                    setPricesBasket([
                      {
                        addedByVoucher: false,
                        expectedDeliveryDate: dyBasketItem.expectedDeliveryDate,
                        id: +dyBasketItem.skuCode,
                        isBackOrder: dyBasketItem.isBackOrder,
                        isDirect: dyBasketItem.isDirectDelivery,
                        isHazardous: dyBasketItem.isHazardous,
                        isLongLength: dyBasketItem.isLongLength,
                        m2mHeight: dyBasketItem?.length || 0,
                        m2mScrewholes: dyBasketItem?.screwholes || null,
                        m2mWidth: dyBasketItem?.width || 0,
                        name: dyBasketItem.name,
                        prices: {
                          decimalSalePercentage: dyBasketItem.skuOffers.discount,
                          price: dyBasketItem.skuOffers.was || dyBasketItem.price,
                          priceBreaks: dyBasketItem.priceBreaks,
                          salePercentage: dyBasketItem.skuOffers.discount,
                          salePrice: dyBasketItem.skuOffers.now,
                          vatPrice: dyBasketItem.vatPrice,
                          vatPriceBreaks: dyBasketItem.vatPriceBreaks,
                        },
                        quantity: quantity || 1,
                        skuCode: dyBasketItem.skuCode,
                        stockLevel: dyBasketItem.stockLevel,
                        url: dyBasketItem.skuUrl,
                        leadTime: dyBasketItem.leadTimeInfo,
                      },
                    ])
                  )
                );
                let price = dyBasketItem.price;
                // apply bulk discount to price
                dyBasketItem.priceBreaks.forEach((priceBreak) => {
                  if (quantity >= priceBreak.quantity) {
                    price = priceBreak.price;
                  }
                });
                dispatch(
                  setGuestBasket({
                    guestBasketTotal: quantity * price,
                    guestdyBasketItems: setPricesBasket([
                      {
                        addedByVoucher: false,
                        expectedDeliveryDate: dyBasketItem.expectedDeliveryDate,
                        id: +dyBasketItem.skuCode,
                        isBackOrder: dyBasketItem.isBackOrder,
                        isDirect: dyBasketItem.isDirectDelivery,
                        isHazardous: dyBasketItem.isHazardous,
                        isLongLength: dyBasketItem.isLongLength,
                        m2mHeight: dyBasketItem?.length || 0,
                        m2mScrewholes: dyBasketItem?.screwholes || null,
                        m2mWidth: dyBasketItem?.width || 0,
                        name: dyBasketItem.name,
                        prices: {
                          decimalSalePercentage: dyBasketItem.skuOffers.discount,
                          price: dyBasketItem.skuOffers.was || dyBasketItem.price,
                          priceBreaks: dyBasketItem.priceBreaks,
                          salePercentage: dyBasketItem.skuOffers.discount,
                          salePrice: dyBasketItem.skuOffers.now,
                          vatPrice: dyBasketItem.vatPrice,
                          vatPriceBreaks: dyBasketItem.vatPriceBreaks,
                        },
                        quantity: quantity || 1,
                        skuCode: dyBasketItem.skuCode,
                        stockLevel: dyBasketItem.stockLevel,
                        url: dyBasketItem.skuUrl,
                        leadTime: dyBasketItem.leadTimeInfo,
                        brand: dyBasketItem.brand,
                        category: dyBasketItem.categoryLvl3Name,
                      },
                    ]),
                  })
                );
              }
            }
          }
        })
        .catch();
    };
    if (dySkuCode) {
      addDySku();
    }
    setDySkuCode('');
  }, [dySkuCode]);

  useEffect(() => {
    const existingGuestBasket = window.localStorage.getItem('guestBasket');
    const parsedExistingGuestBasket = existingGuestBasket ? JSON.parse(existingGuestBasket) : [];
    setGuestUserBasket(parsedExistingGuestBasket);

    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, value) {
      const event: any = new Event('itemInserted');
      const args: [key: string, value: string] = [key, value];
      event.value = value;
      event.key = key;

      document.dispatchEvent(event);
      originalSetItem.apply(this, args);
      if (key === 'increasinglyAddToBasket') localStorage.removeItem('increasinglyAddToBasket');
      if (key === 'dy_atb') localStorage.removeItem('dy_atb');
      if (key === 'loadFoundItExp' && value === '1') dispatch(setIsFoundItExp(true));
    };

    document.addEventListener('itemInserted', localStorageSetHandler, false);
    return () => document.removeEventListener('itemInserted', localStorageSetHandler, false);
  }, [localStorageSetHandler]);

  useEffect(() => {
    if (localStorage.getItem('VAT') !== showVat.toString()) {
      dispatch(setVAT(localStorage.getItem('VAT') === 'true' ? true : false));
    }
  }, [showVat, dispatch]);

  useEffect(() => {
    if ((getCookies('authToken') === '' || getCookies('authEmail') === '') && guestUserBasket) {
      const parsedGuestBasketItems: Basket[] = guestUserBasket;
      const parsedGuestBasketTotal = guestUserBasket.reduce(
        function (prev: { sum: number }, curr: Basket) {
          const isMadeToMeasure =
            curr.m2mHeight !== null && curr.m2mWidth !== null && curr.m2mScrewholes !== null;
          if (isMadeToMeasure) {
            prev.sum +=
              curr.prices.price * (curr.m2mHeight / 1000) * (curr.m2mWidth / 1000) * curr.quantity;
          } else {
            if (curr.prices.salePrice) {
              prev.sum += curr.prices.salePrice * curr.quantity;
            } else {
              prev.sum += curr.prices.price * curr.quantity;
            }
          }
          return prev;
        },
        { sum: 0 }
      ).sum;

      dispatch(
        setGuestBasket({
          guestBasketTotal: parsedGuestBasketTotal,
          guestBasketItems: parsedGuestBasketItems,
        })
      );
    }
  }, [dispatch, guestUserBasket]);

  const getBasketQuantity = () => {
    return basketItems.reduce((a, { quantity }) => a + quantity, 0);
  };

  const getBasketSkus = () => {
    return basketItems.map((basketItem) => basketItem.skuCode);
  };

  const getBasketContents = () => {
    return basketItems.map((basketItem) => {
      return {
        id: basketItem.skuCode,
        quantity: basketItem.quantity,
        itemPrice: basketItem.prices.price,
      };
    });
  };

  const handleCheckoutClick = (): void => {
    ga('send', 'event', GTagCategory.Checkout, 'Checkout');

    if (typeof gtag === 'function') {
      gtag('event', 'begin_checkout', {
        items: basketItems.map((basketItem) => {
          return {
            id: basketItem.id,
            name: basketItem.name,
            category: basketItem.categoryLvl3Name,
            price: basketItem.prices.price,
            quantity: basketItem.quantity,
            brand: basketItem.brand,
            coupon: '',
          };
        }),
      });
    }

    const authEmail = getCookies('authEmail') ?? '';

    fbEvent({
      eventName: FbEventName.INITIATE_CHECKOUT,
      eventSourceUrl: router.asPath,
      email: authEmail,
      firstName: details?.firstName,
      lastName: details?.lastName,
      contentIds: getBasketSkus(),
      contents: getBasketContents(),
      numItems: String(getBasketQuantity()) ?? '0',
      value: basketTotal,
      contentType: 'product',
    });

    if (getCookies('authToken') !== '' && getCookies('authEmail') !== '') {
      if (details) {
        document.cookie = `IDLWebUser=Username=${details.emailAddress}&LastVisited=${dateToMMDDYYYY(
          new Date()
        )}&activeCompany=${
          companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
        }direct.co.uk&CardCode=${cardCode}&FirstName=${details.firstName};path=/;domain=.${
          companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
        }direct.co.uk;`;
        document.cookie = `IDLBasket=${basketId};path=/;domain=.${
          companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
        }direct.co.uk;`;

        window.location.href = checkoutLink(companyID, {
          UserName: details.emailAddress,
          LastVisited: dateToMMDDYYYY(new Date()),
          ActiveCompany: `${
            companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
          }direct.co.uk`,
          CardCode: cardCode,
          IDLBasket: basketId,
        });
      }
    } else {
      window.location.replace('/account/login?next=checkout');
    }

    // if (getCookies('authToken') !== '' && getCookies('authEmail') !== '') {
    //   window.dataLayer.push({ ecommerce: null });
    //   window.dataLayer.push({
    //     event: 'checkout',
    //     ecommerce: {
    //       checkout: {
    //         products: basketItems.map((basketItem) => {
    //           return {
    //             id: basketItem.id,
    //             price: basketItem.prices.price,
    //             quantity: basketItem.quantity,
    //           };
    //         }),
    //       },
    //     },
    //   });
    //   window.dataLayer.push({
    //     event: 'CT_Checkout_Trigger',
    //   });
    // }
  };

  return (
    <div className="basket-aside" id="basket-aside">
      {basketItems && basketItems.length > 0 ? (
        <>
          <div className="basket__heading">
            <svg
              className="svg-icon rhd-svg-icon"
              width="23"
              height="23"
              viewBox="0 0 23 23"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 .41)" fill="none" fillRule="evenodd">
                <path
                  d="M.351 7.528c-.468 0-.468.881 0 2.644l2.22 10.267a1.675 1.675 0 001.551 1.09h13.756c.682-.001 1.295-.432 1.55-1.09l2.22-10.267c.47-1.763.47-2.644 0-2.644H.352z"
                  fill="currentcolor"
                  fillRule="nonzero"
                ></path>
                <rect
                  stroke="currentcolor"
                  transform="rotate(55 14.787 5)"
                  x="10.287"
                  y="4.5"
                  width="9"
                  height="1"
                  rx=".5"
                ></rect>
                <rect
                  stroke="currentcolor"
                  transform="scale(-1 1) rotate(55 0 -8.856)"
                  x="2.713"
                  y="4.5"
                  width="9"
                  height="1"
                  rx=".5"
                ></rect>
              </g>
            </svg>
            <p>Your Basket</p>
          </div>

          {/* <NextDayDeliveryTimer /> */}

          <div className="basket-container">
            {bankHoliday && <HeaderBasketHoliday companyID={companyID} />}
            {(isLoading || isLoadingUpdate) && (
              <div className="basket__loader">
                <PulseLoader
                  sizeUnit="px"
                  size={20}
                  margin="10px"
                  color={companyID === Company.Ironmongery ? '#EFA847' : '#C7D402'}
                  loading={true}
                />
              </div>
            )}
            <HeaderBasketTable
              basketItems={basketItems}
              basketId={basketId}
              updateBasketItem={handleClickBasketUpdate}
              removeBasketItem={handleClickBasketRemove}
              showVat={showVat}
              // outOfStockSkus={[]}
              companyID={companyID}
              handleClickReset={handleClickReset}
            />
            <HeaderBasketSummaryTable
              basketItems={basketItems}
              basketTotal={basketTotal}
              showVat={showVat}
              companyID={companyID}
              // deliveryThreshold={+thresholdVariation?.threshold || 50}
              // deliveryFee={+thresholdVariation?.price || 6.95}
              deliveryThreshold={50}
              deliveryFee={6.95}
            />
            <div className="rhd-basket__cta">
              <button
                type="button"
                className={`cta-btn cta-btn${
                  companyID === Company.Ironmongery ? '--id' : '--ed'
                } cta-btn--focus-dark ${companyID === Company.Electrical ? 'atb__disabled' : ''}`}
                onClick={handleCheckoutClick}
                disabled={companyID === Company.Electrical}
              >
                Checkout Now
              </button>
              <Link href="/basket">
                <a
                  className={`cta-btn cta-btn--focus-dark cta-btn--transparent${
                    companyID === Company.Ironmongery ? '--id' : '--ed'
                  }`}
                  role="button"
                  tabIndex={0}
                  onClick={handleClickReset}
                >
                  View Your Basket
                </a>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <div className="basket-container">
          <div className="basket__empty">
            <svg
              className="svg-icon rhd-svg-icon rhd-svg-icon--basket-warning"
              width="85"
              height="85"
              viewBox="0 0 23 23"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 .41)" fill="none" fillRule="evenodd">
                <path
                  d="M.351 7.528c-.468 0-.468.881 0 2.644l2.22 10.267a1.675 1.675 0 001.551 1.09h13.756c.682-.001 1.295-.432 1.55-1.09l2.22-10.267c.47-1.763.47-2.644 0-2.644H.352z"
                  fill="currentcolor"
                  fillRule="nonzero"
                ></path>
                <rect
                  stroke="currentcolor"
                  transform="rotate(55 14.787 5)"
                  x="10.287"
                  y="4.5"
                  width="9"
                  height="1"
                  rx=".5"
                ></rect>
                <rect
                  stroke="currentcolor"
                  transform="scale(-1 1) rotate(55 0 -8.856)"
                  x="2.713"
                  y="4.5"
                  width="9"
                  height="1"
                  rx=".5"
                ></rect>
              </g>
            </svg>
            <p>Your basket is currently empty!</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default HeaderBasket;
